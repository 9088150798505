// Component import and 'extends Component' keywords are crucial
import { useEffect, useRef} from 'react';
import {Button, Col} from "antd";
import { Layout,message } from 'antd';
const { Content } = Layout

const BetterRender =( {ehrId, compId, formID, userName, password }) => {

    console.log('Render formId', formID)

    const formMetadata = {
        name: formID
    };
    const resourceUrl = 'https://cdr.ichirf.freshehr.com/rest/v1';
    //   const resourceUrl = 'https://cdr.code4health.org/rest/v1';
    const credentials = {
        username: userName,
        password: password
    };
    const context = {
        language: 'en',
        readonly: false,
    };

    const formEnvironment = {
        variables: [
            {
                name: 'ehrId',
                value: ehrId
            }
        ]
    }
//const compositionId = compId || '';
    const compositionId = compId
    //  const compositionId = '28e813d9-5caf-4302-8274-3966a5b1f819::user::1';
    const component = useRef(null)

    useEffect(() => {
        if (component.current) {
            component.current.formMetadata = formMetadata;
            component.current.formEnvironment = formEnvironment;
            component.current.resourceUrl = resourceUrl;
            component.current.credentials = credentials;
            component.current.context = context;
            component.current.compositionId = compositionId
          //  console.log("Component: ",component)
        }

    }, [compId,  ehrId]);
//console.log('Running Better',component)

       return (
        <><Col>
            <Button type={"primary"} icon={'save'} block
                    onClick={commitComposition}> {compositionId ? 'Update' : 'Save'}
            </Button>
        </Col>
            <Content style={{padding: '0 0px', overflowY: 'scroll', height: '1000px'}}>
                <div style={{background: '#fff', padding: 0, minHeight: 500}}>
                    <form-renderer ref={component}></form-renderer>
                </div>
            </Content>
        </>
    );


    async function commitComposition() {

        const api = component.current.getScriptApi();

        const info = () => {
            message.info('This is a normal message');
        };


        try {

            if (!component.current.compositionId) {

                const response = await api.saveComposition();

                if (!response.success) {
                    throw response.error;
                }
                component.current.compositionId = response.uid

                message.success(`Saved Composition successfully: ${response.uid}`)

            } else {

                console.log('Composition: ', component.current.compositions)
                const response = await api.updateComposition()

                if (!response.success) {
                    throw response.error;
                }

                component.current.compositionId = response.uid

                message.success(`Updated Composition successfully: ${response.uid}`);
            }
        }
         catch (error) {
            // Code that will be executed if there is an exception in the 'try' block
            console.error('An error occurred:', error);
            message.error(`Error on save:  ${error.message}`);
        }
    }


}
export default BetterRender
